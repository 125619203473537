import React, { useState, useEffect } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { createCheckoutSession } from '../api';
import useWindowSize from '../hooks/useWindowSize';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { getPhoto } from '../utils/cacheManager';

const PaymentPage = ({ storedPhoto }) => {
    const [photoPreview, setPhotoPreview] = useState(null);
    const { width, height } = useWindowSize();
    const isMobile = width < 768;
    const isShortScreen = height < 700;
    const navigate = useNavigate();

    useEffect(() => {
        const loadPhotoFromCache = async () => {
            if (storedPhoto && storedPhoto.url) {
                try {
                    const photoBlob = await getPhoto(storedPhoto.url);
                    if (photoBlob) {
                        const photoUrl = URL.createObjectURL(photoBlob);
                        setPhotoPreview(photoUrl);
                    }
                } catch (error) {
                    console.error('Error loading photo from cache:', error);
                }
            }
        };

        loadPhotoFromCache();

        // Cleanup function
        return () => {
            if (photoPreview) {
                URL.revokeObjectURL(photoPreview);
            }
        };
    }, [storedPhoto, photoPreview]);

    const handleCheckout = async (serviceType) => {
        try {
            const session = await createCheckoutSession(serviceType);
            if (session.url) {
                window.location.href = session.url;
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            navigate('/payments');
        }
    };


    return (
        <Box className={` ${isMobile ? "" : " grid px-4 mb-4 mt-24"}`}>
            {photoPreview ? (
                <Typography variant={isMobile ? "h4" : "h3"} className={`font-display ${isMobile && storedPhoto && 'mb-4 text-3xl'} ${!storedPhoto ? 'text-4xl mb-12' : ''}`} gutterBottom>
                    Choose Your Plan To See Results
                </Typography>
            ) :
                (<Typography variant={isMobile ? "h5" : "h3"} className={`font-display ${isMobile && storedPhoto && 'mb-4 text-3xl'} ${!storedPhoto ? 'text-4xl mb-12' : ''}`} gutterBottom>
                    Choose Your Plan
                </Typography>
                )}
            {photoPreview &&
                <Box id="photo-container" className={`${isShortScreen ? 'h-40 w-auto' : 'w-auto h-48'} my-0 mx-auto mb-4`}>
                    <Box className="relative w-auto max-h-48  ">
                        <img src={photoPreview} alt="Uploaded" className="shadow-xl my-0 mx-auto rounded" />
                    </Box>
                </Box>
            }
            <Box className='grid gap-2 mb-4 mx-2'>
                {[
                    <>Confidently decide which possessions are <strong>worth keeping.</strong></>,
                    <>Make sure every purchase meets your standards.</>,
                    <>Trained on <strong>the most reputable data sources</strong> to assess quality, craftsmanship, environmental impact, and more.</>
                ].map((text, index) => (
                    <div key={index} className='flex items-start'>
                        <CheckIcon fontSize="small" className="mr-2 mt-1 flex-shrink-0" />
                        <Typography variant="body2" className='font-serif text-left'>
                            {text}
                        </Typography>
                    </div>
                ))}
            </Box>
            <Box className={`pricing-options isolate mx-auto grid max-w-md grid-cols-1  ${isMobile ? 'gap-y-4' : 'gap-y-8 md:gap-x-8 md:mx-0 md:max-w-none md:grid-cols-3'}`}>
                {isMobile ? (
                    <Box className="pricing-options isolate mx-auto grid grid-cols-1 gap-y-2 max-w-md w-full">
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                border: 2,
                                borderColor: 'black',
                                '&:hover': {
                                    border: 2,
                                    borderColor: 'black',
                                },
                            }}
                            fullWidth
                            className={`font-serif bg-orange-50 text-black rounded-xl !border-2 !border-black ${storedPhoto ? 'h-10' : 'h-12'}`}
                            onClick={() => handleCheckout('weekly')}>
                            <Typography variant={isShortScreen ? "body2" : "body1"} className={"font-serif font-bold"}>7-Day Free Trial</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleCheckout('monthly')}
                            className={`font-serif bg-orange-50 text-black rounded-xl ${storedPhoto ? 'h-10' : 'h-12'}`}>
                            <Typography variant={isShortScreen ? "body2" : "body1"} className="font-serif font-bold">Monthly $9.99</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleCheckout('lifetime')}
                            className={`font-serif bg-orange-50 text-black rounded-xl ${storedPhoto ? 'h-10' : 'h-12'}`}>
                            <Typography variant={isShortScreen ? "body2" : "body1"} className="font-serif font-bold">Lifetime Access $99</Typography>
                        </Button>
                    </Box>
                ) : (
                    <>
                        <Box className="pricing-option relative flex flex-col grow justify-between rounded-3xl ring-gray-900/10 overflow-hidden p-6 xl:p-8 ring-1">
                            <Typography variant="h6" className="font-serif">7-Day Free Trial</Typography>

                            <Button
                                variant="contained"
                                color="primary"
                                className={`font-serif my-4 bg-orange-50 text-black rounded-2xl w-full ${isMobile ? 'h-12' : 'h-16'}`}
                                onClick={() => handleCheckout('weekly')}>
                                Choose this plan
                            </Button>
                        </Box>
                        <Box className="pricing-option relative flex flex-col grow justify-between rounded-3xl ring-gray-900/10  overflow-hidden p-6 xl:p-8 ring-1">
                            <Typography variant="h6" className="font-serif">Monthly Subscription</Typography>
                            <Typography variant="h4" className="font-serif">$9.99</Typography>
                            <Button variant="contained" color="primary" onClick={() => handleCheckout('monthly')} className={`font-serif my-4 bg-orange-50 text-black rounded-2xl w-full ${isMobile ? 'h-12' : 'h-16'}`}
                            >
                                Choose this plan
                            </Button>
                        </Box>
                        <Box className="pricing-option relative flex flex-col grow justify-between rounded-3xl ring-gray-900/10  overflow-hidden p-6 xl:p-8 ring-1">
                            <Typography variant="h6" className="font-serif">Lifetime Access</Typography>
                            <Typography variant="h4" className="font-serif">$99</Typography>
                            <Button variant="contained" color="primary" onClick={() => handleCheckout('lifetime')} className={`font-serif my-4 bg-orange-50 text-black rounded-2xl w-full ${isMobile ? 'h-12' : 'h-16'}`}
                            >
                                Choose this plan
                            </Button>
                        </Box>
                    </>

                )

                }

            </Box>
        </Box >

    );
};

export default PaymentPage;
