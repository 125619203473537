import { Box, Button, Link, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserProfile, handleUpdateSubscription, handleCancelSubscription } from '../../api';
import LoadingView from '../../components/Loading';
import useWindowSize from '../../hooks/useWindowSize';

const UserProfile = () => {
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const isMobile = width < 768;


    useEffect(() => {

        const getUserData = async () => {
            try {
                const userData = await fetchUserProfile();
                setUserProfile(userData);
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        getUserData();

    }, []);

    const updateSubscription = async (newPlan) => {
        try {
            const response = await handleUpdateSubscription(newPlan);
            if (response.checkout_url) {
                // Redirect to Stripe Checkout
                window.location.href = response.checkout_url;
            } else {
                setError(new Error('Failed to update subscription'));
            }
        } catch (error) {
            setError(error);
        }
    };

    const cancelSubscription = async () => {
        try {
            const response = await handleCancelSubscription();
            if (response.effective_end_date) {
                const endDate = new Date(response.effective_end_date * 1000).toLocaleDateString();
                alert(`Your subscription will be cancelled on ${endDate}. You can continue using the service until then.`);
                // Refresh user profile to reflect the 'cancelling' state
                const updatedUserData = await fetchUserProfile();
                setUserProfile(updatedUserData);
            }
        } catch (error) {
            setError(error);
        }
    };

    // const deleteAccount = async () => {
    //     try {
    //         const wasDeleted = await handleDeleteAccount();
    //         if (wasDeleted) {
    //             navigate('/login');
    //         }
    //         // If wasDeleted is false, the user stays on the current page
    //     } catch (error) {
    //         setError(error);
    //     }
    // };

    const isLifetime = userProfile?.subscription_plan === "Lifetime Access";


    if (loading) return <div className='container'><LoadingView /></div>;

    return (
        <div className="flex flex-col">
            <div className={`flex-grow ${isMobile ? '' : 'pt-20'} pb-16`}>
                {error ? (
                    <div>
                        <Typography className='font-serif p-4 text-red-500'>Error: {error.message}</Typography>
                        <Button variant="outlined"
                            className="text-sm md:text-base text-black font-serif border-black mt-8"
                            onClick={() => setError(null)}>Back to Profile
                        </Button>
                    </div>
                ) : (
                    <Box className='mt-10'>
                        <Box className="bg-white rounded-full p-4 max-w-md mx-auto mb-6 shadow-lg">
                            <Typography variant="h4" className="font-display mb-4">User Profile</Typography>
                            {!userProfile ?
                                (<> <Typography variant="subtitle" className="font-serif">No user profile found.</Typography>
                                    <Typography className="font-serif body1">Need Help? Message us at </Typography>
                                    <Link className='font-serif text-black' href="mailto:hello@whatsitworth.xyz">hello@whatsitworth.xyz</Link>
                                </>) :
                                (
                                    <>
                                        <Typography className="font-serif text-sm md:text-base mb-2">Email: {userProfile.email}</Typography>
                                        <Typography className="font-serif text-sm md:text-base mb-2">
                                            Subscription Status: {userProfile.subscription_status ? 'Active' : 'Inactive'}
                                        </Typography>
                                        <Typography className="font-serif text-sm md:text-base mb-2">
                                            Current Plan: {userProfile.subscription_plan || 'None'}
                                        </Typography>
                                    </>
                                )
                            }
                        </Box>
                        {!isLifetime && userProfile &&
                            <Box className="mb-6 bg-white rounded-full p-4 md:p-8 max-w-md mx-auto shadow-lg">
                                <Typography variant="h5" className="font-display mb-4">Manage Subscription</Typography>
                                <Box className="space-y-2">
                                    {userProfile.subscription_plan === "Weekly" || userProfile.subscription_plan === "Weekly (Trial)" ? (
                                        <>

                                            <Button
                                                variant="outlined"
                                                onClick={() => updateSubscription('monthly')}
                                                className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                            >
                                                Change to Monthly Plan
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => updateSubscription('lifetime')}
                                                className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                            >
                                                Upgrade to Lifetime Access
                                            </Button>
                                        </>
                                    ) : userProfile.subscription_plan === "Monthly" ? (
                                        <>

                                            <Button
                                                variant="outlined"
                                                onClick={() => updateSubscription('weekly')}
                                                className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                            >
                                                Change to Weekly Plan
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => updateSubscription('lifetime')}
                                                className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                            >
                                                Upgrade to Lifetime Access
                                            </Button>
                                        </>
                                    ) : !userProfile.subscription_plan ? (
                                        <>
                                            <Button
                                                variant="outlined"
                                                onClick={() => navigate('/payment')}
                                                className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                            >
                                                Choose a Plan
                                            </Button>
                                        </>) : (null)

                                    }
                                    {userProfile.subscription_plan &&
                                        <Button
                                            variant="outlined"
                                            onClick={cancelSubscription}
                                            className="text-sm md:text-base text-black font-serif border-black w-3/4 bg-orange-50"
                                        >
                                            Cancel Subscription
                                        </Button>

                                    }
                                </Box>
                            </Box>
                        }
                        <Box className="mb-6 bg-white rounded-full p-4 max-w-md mx-auto shadow-lg text-center">
                            <Typography className='font-display text-black'> Any questions? Email Us </Typography>
                            <Link
                                className='font-serif text-black'
                                href="mailto:hello@whatsitworth.xyz"
                                sx={{
                                    textDecoration: 'underline',
                                    textDecorationColor: 'black'
                                }}
                            >
                                hello@whatsitworth.xyz
                            </Link>
                        </Box>


                        {/* {userProfile &&
                            <Box className="mb-6 bg-white rounded-full p-4 md:p-8 max-w-md mx-auto shadow-lg">
                                <Typography variant="h5" className="font-display mb-4">Account Management</Typography>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={deleteAccount}
                                    className="text-sm md:text-base font-serif w-3/4"
                                >
                                    Delete Account
                                </Button>
                            </Box>
                        } */}
                        {error && <Typography color="error" className="mt-4">{error.message}</Typography>}
                    </Box>
                )}
            </div>
            <footer className={`py-4 ${isMobile ? 'fixed bottom-0 left-0 right-0' : ''}`}>
                <Box className='flex justify-center items-center text-center space-x-2 mt-2'>
                    <Link className='font-serif text-slate-500' underline='hover' variant="body2" href="/terms-of-service">
                        Terms
                    </Link>
                    <Typography className='font-serif'>|</Typography>
                    <Link className='font-serif text-slate-500' underline='hover' variant="body2" href="/privacy-policy">
                        Privacy
                    </Link>
                </Box>
            </footer>
        </div >
    );
};

export default UserProfile;