import React, { useState, useEffect } from 'react';
import { Box, Fab } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';
import ExampleResult from './exampleresult';
import { resizeImage } from '../../hooks/useResize';
import { storePhoto } from "../../utils/cacheManager";

function Example({ setStoredPhoto }) {
    const [isLoading, setIsLoading] = useState(true);
    const { width } = useWindowSize();
    const isMobile = width < 768;
    const navigate = useNavigate();
    const { exampleId } = useParams();

    const exampleData = {
        'asics-gel-1130': {
            image: '/asicsgel1130.jpg',
        },
        'antique-cabinet': {
            image: '/antique-cabinet.png',
        },
        'kingsmith-walking-pad': {
            image: '/walking-pad.jpg',
        },
    };

    const currentExample = exampleData[exampleId] || exampleData['asics-gel-1130'];

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false)
        }, 2250);
    }, []);

    const onDrop = async (acceptedFiles) => {
        if (acceptedFiles.length === 0) return;

        const file = acceptedFiles[0];

        if (!file.type.startsWith('image/')) {
            alert('Please upload an image file.');
            return;
        }

        try {
            const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
            const resizedImage = await resizeImage(file, maxSizeInBytes);
            const photoUrl = await storePhoto(resizedImage);
            const newPhoto = { url: photoUrl, name: file.name, type: 'image/jpeg', size: resizedImage.size };
            setStoredPhoto(newPhoto);

            navigate('/');
        } catch (error) {
            console.error('Error processing image:', error);
        }
    };

    const { getInputProps, getRootProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop
    });

    return (
        <Box className={`flex flex-col ${isMobile ? 'h-[calc(100dvh-8rem)]' : 'h-full'} relative`}>
            {isLoading ? (
                <Box id="photo-container" className="container mx-auto my-0 flex flex-col items-center justify-center h-full">
                    <div className="scanning-container relative flex items-center justify-center w-full h-full">
                        <div className="image-wrapper relative">
                            <img
                                src={currentExample.image}
                                alt="Scanning"
                                className="scanned-image object-contain max-w-full max-h-[70vh] rounded"
                            />
                            <div className="scanning-line absolute left-0 w-full"></div>
                        </div>
                    </div>
                </Box>
            ) : (
                <div className='pb-32'>
                    <ExampleResult exampleId={exampleId} image={currentExample.image} />
                    <div className="fixed bottom-8 left-0 right-0 flex justify-center z-10 mt-4 mb-4">
                        <input
                            {...getInputProps()}
                            id="file-input"
                            className="hidden"
                        />

                        <Fab
                            color="primary"
                            aria-label="upload screenshot"
                            variant="extended"
                            {...getRootProps()}
                            className="bg-white text-black hover:bg-gray-100 font-serif text-xl md:text-2xl justify-center items-center py-2 px-4 md:py-3 md:px-6 rounded-full text-center"
                        >
                            Upload Your Screenshot
                        </Fab>
                    </div>

                </div>)}

        </Box>
    );
}

export default Example;