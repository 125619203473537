import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Box, Button } from '@mui/material';
import useWindowSize from '../../hooks/useWindowSize';

function ResultPage({ result, handleReset }) {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    if (!result) {
        return null; // Return null if the result is empty
    }

    return (
        <>
            <Box>
                <Box className={`overflow-auto text-left  ${isMobile ? 'h-[calc(100dvh-10rem)]' : 'h-auto'}`}>
                    <Box className="p-4 text-black font-serif">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>{result}</ReactMarkdown>
                    </Box>
                </Box>

            </Box>
            <footer>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleReset}
                    className="font-serif mt-4 py-3 px-6 rounded-full text-black text-center bg-white  hover:bg-slate-200  w-full md:w-auto self-center"
                >
                    Start Over
                </Button>
            </footer>
        </>
    );
}

export default ResultPage;